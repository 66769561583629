/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.point-wrapper {
  position: relative;
  flex-basis: 100%;
}

.point-textarea {
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
  background-color: transparent;
  font-family: Arial;
  color: var(--color-fg);

  &.newline {
    top: 1.15rem;
    margin-bottom: 1.15rem;
  }

  .point.main &,
  .message-list-item-link & {
    font-weight: bold;
  }
}

.point {
  display: flex;
  margin: 0 2px;

  &:focus-within,
  &:hover {
    border: 1px solid var(--color-fg);
  }

  &.selected {
    background-color: var(--highlit-bg);
  }
}
