/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.peer-list-header {
  & svg {
    margin-right: 3px;
    vertical-align: top;
  }
}

.peer-list-collapsible-group {
  display: flex;
  justify-content: center;
}

.peer-list-header span {
  margin-left: 2px;
}

.peer-list-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-bg);

  &:hover {
    border-color: var(--hover-border-color);
  }

  & select:disabled {
    appearance: none;
    opacity: 1; // Chromium sets opacity to 0.7 for disabled select elements
  }
}
