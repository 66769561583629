/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000000;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.25);
}

.modal-container {
  position: fixed;
  background-color: var(--color-bg);
  border: 1px solid var(--color-fg);
  max-width: 100%;
  height: 80vh;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  position: absolute;
  top: 0;
  right: 0;
  height: 2rem;
}

.modal-title {
  text-align: center;
  margin: 0.5rem;
}

.modal-content-inner-container {
  margin: 1rem;
}

.modal-content-outer-container {
  position: absolute;
  // padding: 2rem;
  left: 0;
  width: 100%;
  height: calc(100% - 1.5rem);
  overflow: auto;
}

.modal-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 2rem;

  & .button {
    padding: 0 0.5rem;
  }
}
