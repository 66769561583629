/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
:root {
  --color-fg: black;
  --color-bg: white;
  --inverted-color-fg: white;
  --inverted-color-bg: black;
  --highlit-bg: #a8a8a8;
  --gray: gray;
  // Default color to be overridden
  --current-identity-color: var(--gray);
}

[data-theme="dark"] {
  --color-fg: white;
  --color-bg: black;
  --inverted-color-fg: black;
  --inverted-color-bg: white;
  // Change highlitBg in ./utils/stylesGenerators.ts as well
  --highlit-bg: #2a2a2a;
}
