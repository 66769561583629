/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.app-wrapper {
  height: 100%;
  color: var(--color-fg);
  fill: var(--color-fg);
  border-color: var(--color-fg);
  background-color: var(--color-bg);
}

select {
  color: var(--color-fg);
  border: solid 1px var(--color-fg);
  background-color: var(--color-bg);
}

input[type=text] {
  outline: none;
  border: none;
  color: var(--color-fg);
  background-color: var(--color-bg);
  border: 1px solid var(--color-fg);
  width: 100%;

  & + button {
    border: 1px solid var(--color-fg);
    border-left: none;
  }
}
