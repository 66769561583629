/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.banner {
  background-color: transparent;
  border: none;
  color: var(--color-fg);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 100;
  cursor: pointer;

  background-image: var(--backgroundImageAuthorColor);
  &:hover {
    background-image: var(--backgroundImageColorFG);
  }

  background-repeat: no-repeat;
  // Magic numbers to stretch the svg just the right amount
  background-size: 132% 100%;
  background-position: center;

  // TODO: max-width styles might be unnecessary
  max-width: 95%;
  max-width: -webkit-fill-available;
  max-width: -moz-available;

  .message-list-item-top-bar &,
  .reply-history-item-top-bar & {
    position: relative;
    top: 0.2rem;
  }

  & a {
    text-decoration: none;
    color: var(--color-fg);
  }
}
