/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.button {
  color: var(--color-fg);
  background-color: var(--color-bg);
  border: none;
  height: 100%;

  &:disabled {
    color: gray;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:disabled):hover:not(.no-hover) {
    background-color: var(--highlit-bg);

    &.red {
      background-color: red;
    }
  }

  .selected & {
    background-color: var(--highlit-bg);
  }
  .selected &:not(:disabled):hover {
    background-color: var(--color-bg);

    &.red {
      background-color: red;
    }
  }

  .point-wrapper & {
    flex-shrink: 0;
    z-index: 4;
  }
}

.bi {
  height: 100%;
  width: 100%;
}

.button-group {
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto 0;
  box-sizing: border-box;

  .regions-grid &,
  .new-message-button &,
  .message-list-item-link & {
    height: 1.5rem;
  }

  .point-wrapper & {
    position: absolute;

    // Keep banner from overflowing
    max-width: 95%;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
  }
}

.trust-rank {
  margin-left: 4px;
  white-space: nowrap;

  & label, svg {
    vertical-align: top;
  }

  & svg {
    margin-right: 2px;
  }
}
