/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.shape-svg {
  fill: var(--color-fg);
  height: 1rem;
  width: 1rem;

  .region-point &,
  .main-point & {
    height: 1.5em;
    width: 1.5em;
  }

  .top-bar-container & {
    fill: var(--color-bg);
    stroke: var(--current-identity-color);
    stroke-width: 10%;
    height: 100%;
    width: 100%;
  }

  .top-bar-container .button:hover & {
    fill: var(--highlit-bg);
  }
}
