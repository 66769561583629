body {
  height: 100vh;
  margin: 0;
}

body > * {
  font-family: Arial;
}

#root {
  height: 100%;
}
