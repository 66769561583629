/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.peer-graph-page {
  flex: auto;
  border: 1.5px solid var(--gray);
}

#peer-graph {
  height: 100%;

  & marker {
    stroke-width: 0;
  }
}

.node-group {
  & .banner-path {
    stroke-width: 2px;
  }

  & .left-half,
  & .right-half {
    fill: var(--color-bg);
    opacity: 0.5;
  }

  &.source {
    & .left-half,
    & .right-half {
      fill: green;
    }
  }

  &.blocker {
    & .left-half,
    & .right-half {
      fill: blue;
    }
  }

  &.blocked {
    & .left-half,
    & .right-half {
      fill: red;
    }
  }

  &.source.blocker {
    & .left-half {
      fill: blue;
    }

    & .right-half {
      fill: green;
    }
  }

  &.blocker.blocked {
    & .left-half {
      fill: blue;
    }

    & .right-half {
      fill: red;
    }
  }
}

.node-label {
  pointer-events: none;
}

.funnel-path {
  fill: none;
  stroke: var(--color-fg);

  &.funneled {
    fill: var(--color-fg);
  }
}

.funnel-outline-path {
  stroke: none;
  fill: transparent;
}

.edge {
  fill: transparent;
  stroke-opacity: 0.5;

  & ~ defs marker {
    opacity: 0.5;
  }

  &.source,
  &.source ~ defs {
    stroke: green;

    & marker {
      fill: green;
    }
  }

  &.blocker,
  &.blocker ~ defs {
    stroke: blue;

    & marker {
      fill: blue;
    }
  }

  &.blocked,
  &.blocked ~ defs {
    stroke-width: 5;
    stroke: red;

    & marker {
      fill: red;
    }
  }

  &.full {
    stroke-width: 4;
  }

  &.high {
    stroke-width: 3;
  }

  &.mid {
    stroke-width: 2;
  }

  &.low {
    stroke-width: 1;
  }
}

#peer-graph-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 0 1.5px 1.5px;
  border-style: solid;
  border-color: var(--color-fg);
  background-color: var(--color-bg);

  & > button {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 0;
  }
}

.peer-graph-toolbar-options {
  margin-right: 1.5rem;
}

.peer-graph-toolbar-group {
  display: flex;

  &:not(.no-params) {
    justify-content: space-between;
  }

  & > label {
    min-width: 3.5rem;
  }

  &.source > label.shown {
    background-color: green;
  }

  &.blocker > label.shown {
    background-color: blue;
  }

  &.blocked > label.shown {
    background-color: red;
  }
}

.peer-graph-to-peers {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
}

.peer-graph-to-peer-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

// TODO: Limit length of long peer name in peer graph and also in toolbar
