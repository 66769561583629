/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.message-list-item,
.new-message-button {
  margin: 0.1rem;
}

.message-list-item-top-bar {
  display: flex;
  justify-content: space-between;
}

.message-list-item-link,
.new-message-button {
  display: block;
  position: relative;
  padding: 3px;
  border-color: var(--gray);
  border-style: solid;
  border-width: 1.5px;
  text-decoration: none;
  color: var(--color-fg);

  & * {
    cursor: pointer;
  }

  &:hover:not(.no-hover) {
    // !important used to override inline styles
    border-color: var(--color-fg) !important;
  }

  & .sub-points {
    margin-left: 1rem;
  }

  &.selected {
    background-color: var(--highlit-bg);
  }
}

.new-message-button, .message-list-item-link.draft {
  border-width: 1.5px;
  border-style: dashed;
}

.new-message-text {
  flex-basis: 100%;
  line-height: 1.5rem;
  font-style: italic;
  padding-left: 1rem;
}
