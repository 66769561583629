/*
  Copyright (C) 2022 by USHIN, Inc.

  This file is part of U4U.

  U4U is free software: you can redistribute it and/or modify
  it under the terms of the GNU Affero General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  U4U is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU Affero General Public License for more details.

  You should have received a copy of the GNU Affero General Public License
  along with U4U.  If not, see <https://www.gnu.org/licenses/>.
*/
.region {
  overflow: hidden;
  background-color: var(--color-bg);
}

.region-inner-container {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .top.region & {
    padding-top: 0.7rem;
  }
}

.region-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-point-wrapper {
  margin: auto;
  left: 0;
  right: 0;
}

.top.region .spacer {
  font-size: x-large;
  margin: 0 auto 0.7rem auto;
}

.region .drop-target {
  height: 100%;
  min-height: 50px;
}

// Wrapping HoverLine in a relatively positioned div allows us to
// add a border which takes up the margin between two points.
// Since HoverLine is a drop target, this ensures that the margin
// between two points always accepts dropped points.
.hover-line-wrapper {
  position: relative;
}

.hover-line {
  position: absolute;
  background-color: var(--color-bg);
  width: 100%;
  height: 1.4px;
  top: -2px;
  border: 2px solid var(--color-fg);
  z-index: 12;
}
